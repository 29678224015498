/* eslint-disable no-unused-vars */
import { Icon } from '@iconify/react';
import PersonIcon from '@mui/icons-material/Person';
import GiteIcon from '@mui/icons-material/Gite';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { Lock } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MessageIcon from '@mui/icons-material/Message';
import BookIcon from '@mui/icons-material/Book';
import PolicyIcon from '@mui/icons-material/Policy';
import { PATH_DASHBOARD } from '../../../routes/paths';

const navConfig = [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" /> },
      // { title: 'Profile', path: '/dashboard/editadmin', icon: <PeopleAltIcon /> },
      { title: 'User', path: PATH_DASHBOARD.listing.listing, icon: <FormatListBulletedIcon /> },
      { title: 'Sub Admins', path: PATH_DASHBOARD.spell.spell, icon: <PersonIcon /> },
      { title: 'Amount', path: PATH_DASHBOARD.amount.amount, icon: <Icon icon="ri:dashboard-line" /> },
      { title: 'Percentage', path: PATH_DASHBOARD.percantage.addpercantage, icon: <Icon icon="ri:dashboard-line" /> },
      { title: 'Faq', path: PATH_DASHBOARD.faq.faq, icon: <RamenDiningIcon /> },

      { title: 'Reward', path: PATH_DASHBOARD.reward.reward, icon: <RamenDiningIcon /> },
      // { title: 'Message', path: PATH_DASHBOARD.message.message, icon: <MessageIcon /> },
      { title: 'Request', path: PATH_DASHBOARD.payouts.payouts, icon: <RamenDiningIcon /> },
      { title: 'Policies', icon: <PolicyIcon/>,
        children: [
          { title: 'Privacy Policy', path: PATH_DASHBOARD.policy('Privacy Policy'), icon: <PolicyIcon/> },
          { title: 'Refund Policy', path: PATH_DASHBOARD.policy('Refund Policy'), icon: <PolicyIcon/> },
          { title: 'Risk Policy', path: PATH_DASHBOARD.policy('Risk Policy'), icon: <PolicyIcon/> },
          { title: 'Disclaimer Policy', path: PATH_DASHBOARD.policy('Disclaimer Policy'), icon: <PolicyIcon/> },
        ]
      },
      // { title: 'Privacy Policy', path: PATH_DASHBOARD.policy('Privacy Policy'), icon: <PolicyIcon/> },
      // { title: 'Refund Policy', path: PATH_DASHBOARD.policy('Refund Policy'), icon: <PolicyIcon/> },
      // { title: 'Risk Policy', path: PATH_DASHBOARD.policy('Risk Policy'), icon: <PolicyIcon/> },
      // { title: 'Disclaimer Policy', path: PATH_DASHBOARD.policy('Disclaimer Policy'), icon: <PolicyIcon/> },
      
      // { title: 'Invoices', path: PATH_DASHBOARD.invoices.invoices, icon: <RamenDiningIcon /> },
      // { title: 'Favorite', path: PATH_DASHBOARD.favorite.favorite, icon: <Icon icon="mdi:favorite" /> },
      // { title: 'Logout', path: '/', icon: <Lock /> },

      // { title: 'Host', path: PATH_DASHBOARD.host.host, icon: <GiteIcon /> },
      // { title: 'Facility', path: PATH_DASHBOARD.facility.facility, icon: <PeopleAltIcon /> },
      // { title: 'Amenity', path: PATH_DASHBOARD.amenity.amenity, icon: <RamenDiningIcon /> },
      
      // { title: 'Blog', path: PATH_DASHBOARD.message.blog, icon: <BookIcon /> },
    ],
  },
];

export default navConfig;
