/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Delete, RemoveRedEyeOutlined,SkipNext } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
// import AddIcon from '@mui/icons-material/Add';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Container, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import LoadingScreen from '../../../components/LoadingScreen';



export default function Allfavorite() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
   const [loader,setloader]=useState(false)
  const columns = useMemo(
    () => [
      {
        accessorKey: 'image',
        header: 'Image',
        size: 10,
        Cell: ({ row }) =>
          row?.original ? (
            <img
              src={row?.original?.listing_images ? row?.original?.listing_images[0]?.image : null}
              alt="Image"
              style={{ width: '65px', height: '50px',}}
            />
          ) : null,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 5,
      },
      {
        accessorKey: 'listing_type',
        header: 'Dwelling',
        size: 10,
      },
      {
        accessorKey: 'nightly',
        header: 'Price',
        size: 5,
      },
      {
        accessorKey: 'no_of_bedroom',
        header: 'Bedroom',
        size: 5,
      },
      {
        accessorKey: 'no_of_bathroom',
        header: 'Bathroom',
        size: 5,
      },
      {
        accessorKey: 'no_of_guest',
        header: 'Guests',
        size: 5,
      },
      // {
      //   accessorKey: 'listing_type',
      //   header: 'Listing Type',
      //   size: 5,
      // },
      // {
      //   accessorKey: 'status',
      //   header: 'Status',
      //   size: 10,
      //   Cell: ({ row }) =>
      //     row?.original?.status == 'Approved' ? (
      //       <Button
      //         fullWidth
      //         variant="contained"
      //         color="success"
      //         endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
      //         onClick={() => {
      //           handleApproved(row?.original?.id);
      //         }}
      //       >
      //         Active
      //       </Button>
      //     ) : (
      //       <Button
      //         fullWidth
      //         variant="contained"
      //         color="error"
      //         endIcon={<Iconify icon={'eva:close-circle-fill'} />}
      //         onClick={() => {
      //           handleApproved(row?.original?.id);
      //         }}
      //       >
      //         Inactive
      //       </Button>
      //     ),
      // },
    
    ],
    []
  );

  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState('all');
  const [allLength, setAllLength] = useState([]);
  const [allMine, setAllMine] = useState([]);
  const [allPublished, setAllPublished] = useState([]);
  const [allDraft, setAllDraft] = useState([]);

  async function AllListings() {
    setloader(true)
    const data = await axiosInstance.get('listing-favourite');
    setTableData(data?.data?.data);
    setloader(false)
  }

  async function AllListingsAll() {
    const data = await axiosInstance.get('listing', {
      params: {
        status: 'all',
      },
    });
    setAllLength(data?.data?.data);
  }

  async function AllListingsMine() {
    const data = await axiosInstance.get('listing', {
      params: { status: 'mine' },
    });
    setAllMine(data?.data?.data);
  }
  async function AllListingsPublished() {
    const data = await axiosInstance.get('listing', {
      params: { status: 'published' },
    });
    setAllPublished(data?.data?.data);
  }
  async function AllListingsDraft() {
    const data = await axiosInstance.get('listing', {
      params: { status: 'draft' },
    });
    setAllDraft(data?.data?.data);
  }

    const filterData=tableData.filter((e)=>e?.is_favourite==1)
   
  console.log(filterData,'filter')

console.log(tableData,'ta')
   

  useEffect(() => {
    AllListings();
    // AllListingsAll();
    // AllListingsMine();
    // AllListingsPublished();
    // AllListingsDraft();
  }, [status]);

  async function handleDelete(rowdata) {
    try {
      await axiosInstance.delete(`listing/${rowdata}`).then((response) => {
        if (response?.data?.status === true) {
          enqueueSnackbar('Listing Deleted Successfully');
          AllListings();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  async function handleApproved(id) {
    console.log(id);
    try {
      await axiosInstance.get(`listing-status-change/${id}`).then((response) => {
        console.log(response?.data);
        if (response?.data?.status === true) {
          enqueueSnackbar(response?.data?.message);
          AllListings();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  const tabStyles = (currentStatus) => ({
    border: '1px solid',
    borderColor: currentStatus === status ? 'success.main' : 'error.main',
    backgroundColor: currentStatus === status ? 'success.main' : 'white',
    color: currentStatus === status ? 'white' : 'error.main',
    '&:hover': {
      backgroundColor: currentStatus === status ? 'success.dark' : 'error.light',
      color: 'white',
    },
  });

  return (
    <>
    {loader && <LoadingScreen/>}
      <Page title="Favorite List">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading="Favorite List"
            links={[{ name: '', href: '' }]}
            // action={
            //   <Button
            //     variant="contained"
            //     startIcon={<Iconify icon="eva:plus-fill" />}
            //     component={RouterLink}
            //     to={PATH_DASHBOARD.favorite.addfavorite}
            //   >
            //     Add Favorite
            //   </Button>
            // }
          />
{/* 
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Manage
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
              <Button sx={tabStyles('all')} onClick={() => setStatus('all')}>
                All ({allLength?.length})
              </Button>
              <Button sx={tabStyles('mine')} onClick={() => setStatus('mine')}>
                Mine ({allMine?.length})
              </Button>
              <Button sx={tabStyles('published')} onClick={() => setStatus('published')}>
                Published ({allPublished?.length})
              </Button>
              <Button sx={tabStyles('draft')} onClick={() => setStatus('draft')}>
                Draft ({allDraft?.length})
              </Button>
            </Box>
          </Box> */}

          <MaterialReactTable
            columns={columns}
            data={tableData}
            enableRowActions
            renderRowActions={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '5px',
                  justifyContent: 'flex-center',
                }}
              >
                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    navigate('/dashboard/viewfavorite', { state: row.original });
                  }}
                >
                  <RemoveRedEyeOutlined />
                </IconButton>
                {/* <IconButton
                  color="primary"
                  sx={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                  }}
                  onClick={() => {
                    navigate(PATH_DASHBOARD.listing.editlisting(row.original.id), { state: row.original });
                  }}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    handleDelete(row.original.id);
                  }}
                >
                  <Delete />
                </IconButton> */}
              </Box>
            )}
            positionActionsColumn="last"
          />
        </Container>
      </Page>
    </>
  );
}
