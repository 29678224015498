import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import Blog from '../pages/dashboard/blog/Blog';
import AddBlog from '../pages/dashboard/blog/AddBlog';
import EditBlog from '../pages/dashboard/blog/EditBlog';
import Allfavorite from '../pages/dashboard/Favorite/AllFavorite';
import AddFavorite from '../pages/dashboard/Favorite/AddFavorite';
import EditFavorite from '../pages/dashboard/Favorite/EditFavorite';
import ViewFavorite from '../pages/dashboard/Favorite/ViewFavorite';
import ViewBlog from '../pages/dashboard/blog/ViewBlog';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home/> },
    { path: '/login/admin', element: <Login/> },
    { path: '/codemail', element: <CodeMail /> },
    { path: '/login/company', element: <CompanyLogin/> },
    { path: '/login/clinic', element: <ClinicLogin/> },
    { path: '/forget', element: <Register/> },
    { path: '/reset-passsword', element: <ResetPassword usertype={'company'}/> },
    { path: '/clinic-reset-passsword', element: <ResetPassword usertype={'clinic'}/> },
    {
          path: 'dashboard',
          element: (
              <DashboardLayout />
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'home', element: <GeneralAnalytics /> },
            { path: 'dorm', element: <Dorm /> },
            { path: 'editdorm/:id', element: <EditDorm /> },
            { path: 'adddorm', element: <AddDorm /> },
            { path: 'element', element: <Element /> },
            { path: 'editelement/:id', element: <EditElement /> },
            { path: 'comment/:id', element: <Comment /> },
            { path: 'companyProfile', element: <CompanyProfile /> },
            { path: 'addelement', element: <AddElement /> },
            { path: 'rarity', element: <Rarity /> },
            { path: 'editrarity/:id', element: <EditRarity /> },
            { path: 'addrarity', element: <AddRarity /> },
             { path: 'spell', element: <Spell /> },
             { path: 'addspell', element: <AddSpell /> },
             { path: 'editspell/:id', element: <EditSpell /> },

             { path: 'amount', element: <Amount /> },
             { path: 'addamount', element: <AddAmount /> },
             { path: 'editamount/:id', element: <EditAmount /> },
             { path: 'viewamount/:id', element: <ViewAmount/>},


             
             { path: 'faq', element: <Faq /> },
             { path: 'addfaq', element: <AddFaq/> },
             { path: 'editfaq/:id', element: <EditFaq /> },
             { path: 'viewfaq/:id', element: <ViewFaq/>},

             { path: 'percantage', element: <AddPercantage /> },
 
             
             { path: 'reward', element: <Reward/> },
             { path: 'viewreward/:id', element: <ViewReward/>},

             { path: 'viewuser/:id', element: <ViewUser/>},

             
             { path: 'userdetail', element: <UserDetail/> },

        
             { path: 'host', element: <Host /> },
             { path: 'addhost', element: <AddHost /> },
             { path: 'edithost/:id', element: <EditHost /> },
             { path: 'editadmin', element: <EditAdmin /> },
             { path: 'facility', element: <Facility /> },
             { path: 'addfacility', element: <AddFacility /> },
             { path: 'editfacility/:id', element: <EditFacility /> },
             { path: 'amenity', element: <Amenity /> },
             { path: 'addamenity', element: <AddAmenity /> },
             { path: 'editamenity/:id', element: <EditAmenity /> },
             { path: 'listing', element: <Listing /> },
             { path: 'addlisting', element: <AddListing /> },
             { path: 'editlisting/:id', element: <EditListing /> },
             { path: 'duplicate/:id', element: <Duplicate/> },
             { path: 'change_password', element: <ResetPassword/> },


             
             { path: 'viewlisitng', element: <ViewLisitng /> },
             { path: 'booking', element: <Booking /> },
             { path: 'viewbooking', element: <ViewBooking /> },
             { path: 'confirmbooking', element: <ConfirmBooking /> },

             { path: 'trip', element: <Trip/> },
             { path: 'viewtrip', element: <TripView /> },
             { path: 'message', element: <Message /> },
             { path: 'blog', element: <Blog /> },
             { path: 'addblog', element: <AddBlog /> },
             { path: 'editblog/:id', element: <EditBlog /> },
             { path: 'viewblog/:id', element: <ViewBlog /> },

             { path: 'payouts', element: <Payouts /> },
             { path: 'payoutssearch/:id', element: <PayoutsSearch /> },
             { path: 'payoutsdetails', element: <PayoutsDetails /> },
             { path: 'invoices', element: <Invoices /> },
             { path: 'invoicesdetails', element: <InvoicesDetails /> },
             { path: 'allfavorite', element: <Allfavorite /> },
             { path: 'addfavorite', element: <AddFavorite /> },
             { path: 'editfavorite/:id', element: <EditFavorite /> },
             { path: 'viewfavorite', element: <ViewFavorite /> },
             { path: 'policy/:name', element: <Policy /> },


            {
              path: 'e-commerce',
              children: [
                { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
              ],
            },
          ],
        },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const CompanyLogin = Loadable(lazy(() => import('../pages/auth/CompanyLogin')));
const ClinicLogin = Loadable(lazy(() => import('../pages/auth/ClinicLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const CodeMail = Loadable(lazy(() => import('../pages/auth/CodeMail')));


const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const Dorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/Dorm')));
const EditDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/EditDorm')));
const AddDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/AddDorm')));

const Element = Loadable(lazy(() => import('../pages/dashboard/Company/Element')));
const EditElement = Loadable(lazy(() => import('../pages/dashboard/Company/EditElement')));
const Comment = Loadable(lazy(() => import('../pages/dashboard/Company/Comment')));
const CompanyProfile = Loadable(lazy(() => import('../pages/dashboard/Company/CompanyProfile')));
const AddElement = Loadable(lazy(() => import('../pages/dashboard/Company/AddElement')));



const Rarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/Rarity')));
const EditRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/EditRarity')));
const AddRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/AddRarity')));
const EditAdmin = Loadable(lazy(() => import('../pages/dashboard/EditAdmin/EditAdmin')));


const UserDetail = Loadable(lazy(() => import('../pages/dashboard/Provider/UserDetail')));

const Spell = Loadable(lazy(() => import('../pages/dashboard/Provider/Spell')));
const AddSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/AddSpell')));
const EditSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/EditSpell')));

const Amount = Loadable(lazy(() => import('../pages/dashboard/Amount/Amount')));
const AddAmount = Loadable(lazy(() => import('../pages/dashboard/Amount/AddAmount')));
const EditAmount = Loadable(lazy(() => import('../pages/dashboard/Amount/EditAmount')));
const ViewAmount= Loadable(lazy(() => import('../pages/dashboard/Amount/ViewAmount')));

const Faq = Loadable(lazy(() => import('../pages/dashboard/Faq/Faq')));
const AddFaq = Loadable(lazy(() => import('../pages/dashboard/Faq/AddFaq')));
const EditFaq = Loadable(lazy(() => import('../pages/dashboard/Faq/EditFaq')));
const ViewFaq= Loadable(lazy(() => import('../pages/dashboard/Faq/ViewFaq')));
const AddPercantage = Loadable(lazy(() => import('../pages/dashboard/Percantage/AddPercantage')));


const Reward = Loadable(lazy(() => import('../pages/dashboard/Reward/Reward')));
const ViewReward = Loadable(lazy(() => import('../pages/dashboard/Reward/ViewReward')));

const ViewUser = Loadable(lazy(() => import('../pages/dashboard/Provider/ViewUser')));

const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const Host = Loadable(lazy(() => import('../pages/dashboard/Host/Host')));
const AddHost = Loadable(lazy(() => import('../pages/dashboard/Host/AddHost')));
const EditHost = Loadable(lazy(() => import('../pages/dashboard/Host/EditHost')));
const Facility = Loadable(lazy(() => import('../pages/dashboard/Facility/Facility')));
const AddFacility = Loadable(lazy(() => import('../pages/dashboard/Facility/AddFacility')));
const EditFacility = Loadable(lazy(() => import('../pages/dashboard/Facility/EditFacility')));
const Amenity = Loadable(lazy(() => import('../pages/dashboard/Amenity/Amenity')));
const AddAmenity = Loadable(lazy(() => import('../pages/dashboard/Amenity/AddAmenity')));
const EditAmenity = Loadable(lazy(() => import('../pages/dashboard/Amenity/EditAmenity')));
const Listing = Loadable(lazy(() => import('../pages/dashboard/Listing/Listing')));
const AddListing = Loadable(lazy(() => import('../pages/dashboard/Listing/AddListing')));
const EditListing = Loadable(lazy(() => import('../pages/dashboard/Listing/EditListing')));
const Duplicate = Loadable(lazy(() => import('../pages/dashboard/Listing/Duplicate')));
const Policy = Loadable(lazy(() => import('../pages/dashboard/Policy')));

const ViewLisitng = Loadable(lazy(() => import('../pages/dashboard/Listing/ViewLisitng')));
const Booking = Loadable(lazy(() => import('../pages/dashboard/Booking/Booking')));
const ViewBooking = Loadable(lazy(() => import('../pages/dashboard/Booking/ViewBooking')));
const ConfirmBooking = Loadable(lazy(() => import('../pages/dashboard/Booking/ConfirmBooking')));

const Trip = Loadable(lazy(() => import('../pages/dashboard/Trip/Trip')));
const TripView = Loadable(lazy(() => import('../pages/dashboard/Trip/Tripview')));


const Message = Loadable(lazy(() => import('../pages/dashboard/Message/Message')));
const Payouts = Loadable(lazy(() => import('../pages/dashboard/Payouts/Payouts')));
const PayoutsSearch = Loadable(lazy(() => import('../pages/dashboard/Payouts/PayoutsSearch')));
const PayoutsDetails = Loadable(lazy(() => import('../pages/dashboard/Payouts/PayoutsDetails')));
const Invoices = Loadable(lazy(() => import('../pages/dashboard/Invoices/Invoices')));
const InvoicesDetails = Loadable(lazy(() => import('../pages/dashboard/Invoices/InvoicesDetails')));






