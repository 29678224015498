/* eslint-disable prefer-template */
/* eslint-disable no-alert */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect,useRef } from 'react';
import axiosInstance from '../../../utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';

const EditBlog = () => {
  const editor = useRef(null);
  const [formData, setFormData] = useState({
    heading: '',
    sub_heading: '',
    description: ''
  });

  const [errors, setErrors] = useState({
    heading: '',
    sub_heading: '',
    description: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      // Fetch the existing blog data
      const fetchBlog = async () => {
        try {
          const response = await axiosInstance.get(`blog/${id}`);
          setFormData({
            heading: response.data.heading,
            sub_heading: response.data.sub_heading,
            description: response.data.description,
          });
        } catch (error) {
          console.error('Error fetching blog:', error);
        }
      };
      fetchBlog();
    }
  }, [id]);

  const validate = () => {
    const newErrors = {};
    if (!formData.heading) newErrors.heading = 'Heading is required';
    if (!formData.sub_heading) newErrors.sub_heading = 'Sub-Heading is required';
    if (!formData.description) newErrors.description = 'Description is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDescriptionChange = (value) => {
    setFormData({
      ...formData,
      description: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);
      try {
        const res = id
          ? await axiosInstance.put(`blog/${id}`, formData)
          : await axiosInstance.post('blog', formData);
        console.log(res);
        navigate('/dashboard/blog'); // Navigate to the desired route on success
      } catch (error) {
        console.error('Error:', error);
        alert('Failed to save blog: ' + (error.response?.data?.message || error.message));
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const formContainerStyle = {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
  };

  const headingStyle = {
    textAlign: 'center',
  };

  const formGroupStyle = {
    marginBottom: '15px',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
  };

  const flexContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  const flexItemStyle = {
    flex: '1',
    marginRight: '10px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '5px',
  };

  const errorStyle = {
    color: 'red',
    fontSize: '0.875em',
  };

  const buttonStyle = {
    display: 'block',
    width: '100%',
    padding: '10px',
    marginTop: '60px', // Add margin to the button
    backgroundColor: isSubmitting ? '#ccc' : '#2E3F6E',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: isSubmitting ? 'not-allowed' : 'pointer',
  };

  const editorConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    toolbarAdaptive: false,
    height: 400,
    uploader: {
      insertImageAsBase64URI: true, // Use base64 to insert images
    },
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'image',
      'table',
      'link',
      'align',
      'undo',
      'redo',
      'eraser',
      'copyformat',
      'hr',
    ],
  };

  return (
    <div style={formContainerStyle}>
      <h1 style={headingStyle}>{id ? 'Edit Blog' : 'Create New Blog'}</h1>
      <form onSubmit={handleSubmit}>
        <div style={formGroupStyle}>
          <div style={flexContainerStyle}>
            <div style={flexItemStyle}>
              <label htmlFor="heading" style={labelStyle}>Heading:</label>
              <input
                type="text"
                id="heading"
                name="heading"
                value={formData.heading}
                onChange={handleChange}
                style={inputStyle}
              />
              {errors.heading && <span style={errorStyle}>{errors.heading}</span>}
            </div>
            <div style={{ ...flexItemStyle, marginRight: '0' }}>
              <label htmlFor="sub_heading" style={labelStyle}>Sub-Heading:</label>
              <input
                type="text"
                id="sub_heading"
                name="sub_heading"
                value={formData.sub_heading}
                onChange={handleChange}
                
                style={inputStyle}
              />
              {errors.sub_heading && <span style={errorStyle}>{errors.sub_heading}</span>}
            </div>
          </div>
        </div>
        <div style={formGroupStyle}>
          <label htmlFor="description" style={labelStyle}>Description:</label>
          <JoditEditor
          ref={editor}
            value={formData.description}
            // onChange={handleDescriptionChange}
            config={editorConfig}// tabIndex is optional
            onBlur={newContent => handleDescriptionChange(newContent)}
            // You can add more props here as needed
          />
          {errors.description && <span style={errorStyle}>{errors.description}</span>}
        </div>
        <button
          type="submit"
          style={buttonStyle}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default EditBlog;
