/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Delete, RemoveRedEyeOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
// import AddIcon from '@mui/icons-material/Add';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Container, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import LoadingScreen from '../../../components/LoadingScreen';

export default function Listing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loader,setloader]=useState(false)
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 10,
      },
      {
        accessorKey: 'heading',
        header: 'Heading',
        size: 5,
      },
      {
        accessorKey: 'sub_heading',
        header: 'Sub Heading',
        size: 5,
      },
      {
        accessorKey: 'date',
        header: 'Blog Add Date',
        size: 5,
      },
      // {
      //   accessorKey: 'status',
      //   header: 'Status',
      //   size: 10,
      //   Cell: ({ row }) =>
      //     row?.original?.status == "Approved" ? (
      //       <Button
      //         fullWidth
      //         variant="contained"
      //         color="success"
      //         endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
      //         onClick={() => {
      //           handleApproved(row?.original?.id);
      //         }}
      //       >
      //         Active
      //       </Button>
      //     ) : (
      //       <Button
      //         fullWidth
      //         variant="contained"
      //         color="error"
      //         endIcon={<Iconify icon={'eva:close-circle-fill'} />}
      //         onClick={() => {
      //           handleApproved(row?.original?.id);
      //         }}
      //       >
      //         Inactive
      //       </Button>
      //     ),
      // },
    
    ],
    []
  );

  const [tableData, setTableData] = useState([]);

  async function AllListings() {
    setloader(true)
    const data = await axiosInstance.get('blog');
    setTableData(data?.data?.data);
    setloader(false)

  }

  useEffect(() => {
    AllListings();
  }, []);

  async function handleDelete(rowdata) {
    try {
      await axiosInstance.delete(`blog/${rowdata}`).then((response) => {
        if (response?.data?.status === true) {
          enqueueSnackbar('Blog Deleted Successfully');
          AllListings();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }


  // status

  // async function handleApproved(id) {
  //   console.log(id);
  //   try {
  //     await axiosInstance.get(`listing-status-change/${id}`).then((response) => {
  //       console.log(response?.data);
  //       if (response?.data?.status === true) {
  //         enqueueSnackbar(response?.data?.message);
  //         AllListings();
  //       }
  //     });
  //   } catch (error) {
  //     enqueueSnackbar(error?.message, {
  //       variant: 'error',
  //     });
  //     console.error(error);
  //   }
  // }

  return (
    <>
    {loader && <LoadingScreen/>}
      <Page title="Blog">

        <Container maxWidth="lg">

          <HeaderBreadcrumbs
            heading="Blog"
            links={[{ name: '', href: '' }]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={PATH_DASHBOARD.message.addblog}
              >
                New Blog
              </Button>
            }
          />
          <MaterialReactTable
            columns={columns}
            data={tableData}
            enableRowActions
            renderRowActions={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '5px',
                  justifyContent: 'flex-center',
                }}
              >

                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    navigate('/dashboard/viewlisitng'  , { state: row.original });
                    navigate(PATH_DASHBOARD.message.viewblog(row.original.id), { state: row.original });

                  }}
                >
                  <RemoveRedEyeOutlined />
                </IconButton>

                <IconButton
                  color="primary"
                  sx={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                  }}
                  onClick={() => {
                    navigate(PATH_DASHBOARD.message.editblog(row.original.id), { state: row.original });
                  }}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    handleDelete(row.original.id);
                  }}
                >
                  <Delete />
                </IconButton>

              </Box>

            )}
            positionActionsColumn="last"
          />

        </Container>

      </Page>
      
    </>
  );
}
