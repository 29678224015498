/* eslint-disable react/no-danger */
/* eslint-disable import/order */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import { useParams } from 'react-router-dom';

const ViewBlog = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axiosInstance.get(`blog/${id}`);
        setBlog(response?.data.blog);
        console.log(response.data.blog,'blog');
      } catch (error) {
        setError('Error fetching blog');
        console.error('Error fetching blog:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={cardContainerStyle}>
      <div style={cardStyle}>
        <h2 style={headingStyle}>{blog.heading}</h2>
        <h4 style={subHeadingStyle}>{blog.sub_heading}</h4>
        <div style={descriptionStyle} dangerouslySetInnerHTML={{ __html: blog.description }} />
      </div>
    </div>
  );
};

const cardContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#f9f9f9',
};

const cardStyle = {
  maxWidth: '600px',
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '10px',
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const headingStyle = {
  marginBottom: '10px',
};

const subHeadingStyle = {
  marginBottom: '20px',
  color: '#555',
};

const descriptionStyle = {
  fontSize: '16px',
  lineHeight: '1.6',
};

export default ViewBlog;
